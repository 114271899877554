$(document).ready(function(){

    $('body').on('click', '.add-to-cart-btn', function(el){
        var form = $(this).parents(".add-to-cart-form");

        var form_data = form.serializeArray();


        var product_id = form.data('product_id');
        var quantity = $('input[name="quantity-'+product_id+'"]').val();
        form_data.push({name:'quantity', value: quantity});

        $.ajax({
            method: "POST",
            url: form.attr('action'),
            data: form_data,
            success: function (data) {
                if(data.success) {
                    $('.component-header .cart-contents').html('(' + data.count + ' termék, ' + data.price.toLocaleString('hu-HU', {
                            maximumFractionDigits: 0,
                            style: 'currency',
                            currency: 'HUF'
                        }) + ')');

                    var myToastEl = document.getElementById('breadToast');
                    var options = {
                        'autohide': true,
                        'delay': 5000,
                    };
                    var myToast = window.bootstrap.Toast.getOrCreateInstance(myToastEl, options);
                    myToastEl.querySelector('strong.me-auto').innerHTML = el.currentTarget.offsetParent.parentElement.querySelector('td:nth-of-type(2)').innerText;
                    myToastEl.querySelector('.toast-body span').innerHTML = 'A termékből bekerült ' + quantity + ' darab a kosárba.';
                    myToastEl.querySelector('.toast-body form .product-data').innerHTML =
                        '<input type="hidden" name="id" value="' + product_id + '">' +
                        '<input type="hidden" name="quantity" value="' + quantity + '">' +
                        '<div class="mt-2 pt-2 border-top text-end">' +
                        '<button type="button" class="remove-from-cart btn btn-secondary btn-sm" data-bs-dismiss="toast">Mégse</button>' +
                        '</div>';
                    myToast.show();
                }
                else if(typeof data.warning != 'undefined'){
                    var myToastEl = document.getElementById('breadToast');
                    var options = {
                        'autohide': true,
                        'delay': 5000,
                    };
                    var myToast = window.bootstrap.Toast.getOrCreateInstance(myToastEl, options);
                    myToastEl.querySelector('strong.me-auto').innerHTML = el.currentTarget.offsetParent.parentElement.querySelector('td:nth-of-type(2)').innerText;
                    myToastEl.querySelector('.toast-body span').innerHTML = data.warning;

                    myToast.show();
                }
                else if(typeof data.error != 'undefined'){
                    var alertModalElement = document.getElementById('alertModal');

                    if (alertModalElement) {
                        alertModalElement.querySelector('.alert-modal-title').innerHTML = 'Figyelem!'
                        alertModalElement.querySelector('.alert-modal-message').innerHTML = data.error;
                        $('input[name="quantity-'+product_id+'"]').val(data.max_amount);
                        const alertModal = window.bootstrap.Modal.getOrCreateInstance(alertModalElement);
                        alertModal.show();
                    }
                }
            }
        });

    });


    $('body').on('click', '.remove-from-cart', function(){
        var form = $(this).parents('form');
        var form_data = form.serializeArray();

        var button = $(this);

        $.ajax({
            method: "POST",
            url: form.attr('action'),
            data: form_data,
            success: function (data) {
                if(data.count) {
                    button.parents('.component-product-list-item').remove();
                    $('.component-header .cart-contents').html('(' + data.count + ' termék, ' + data.price.toLocaleString('hu-HU', {
                            maximumFractionDigits: 0,
                            style: 'currency',
                            currency: 'HUF'
                        }) + ')');
                    $('.cart-sum-price').html(data.price.toLocaleString('hu-HU', {
                        maximumFractionDigits: 0,
                        style: 'currency',
                        currency: 'HUF'
                    }));
                    $('.cart-sum-price-gross').html(data.price_gross.toLocaleString('hu-HU', {
                        maximumFractionDigits: 0,
                        style: 'currency',
                        currency: 'HUF'
                    }));
                    $('select[name="shipping_type"]').change();
                }
                else{
                    location.reload();
                }
            }
        });


    });

    $('select[name="shipping_type"]').change(function(){
        if($(this).val() == 'shipping' || $(this).val() == 'own_shipping') {
            var select = $(this);
            $.ajax({
                method: 'get',
                url: '/get-shipping-cost',
                data: { shipping_type: select.val()  },
                success: function (data) {
                    if(typeof data.custom_price == 'undefined' || ! data.custom_price ) {
                        var price = data.shipping_cost.toLocaleString('hu-HU', {
                            maximumFractionDigits: 0,
                            style: 'currency',
                            currency: 'HUF'
                        });
                        var gross_price = data.shipping_cost_gross.toLocaleString('hu-HU', {
                            maximumFractionDigits: 0,
                            style: 'currency',
                            currency: 'HUF'
                        });
                        if (!$('.shipping-price-content').length) {
                            var shipping_price_element = $('<div class="shipping-price-content">Szállítási díj: <span class="sipping-price">' + price + '</span>' +
                                '<span class="gross-price-container"> (bruttó <span class="shipping-price-gross">' + gross_price + '</span>)</span></div>');
                            shipping_price_element.appendTo($('.sum-price-container'));
                        }
                        else {
                            $('.sipping-price').html(price);

                            if($('.shipping-price-gross').length) {
                                $('.shipping-price-gross').html(gross_price);
                            }
                            else{
                                $('.shipping-price-content').append(' <span class="gross-price-container">(bruttó <span class="shipping-price-gross">' + gross_price + '</span></span>)');
                            }
                            $('.gross-price-container').removeClass('d-none');
                        }
                    }
                    else{
                        if (!$('.shipping-price-content').length) {
                            var shipping_price_element = $('<div class="shipping-price-content">Szállítási díj: <span class="sipping-price">A futárszolgálat díjszabása alapján</span></div>');
                            shipping_price_element.appendTo($('.sum-price-container'));
                        }
                        else{
                            $('.sipping-price').html('A futárszolgálat díjszabása alapján');

                        }

                        $('.gross-price-container').addClass('d-none');
                    }

                }
            });
        }
        else{
            if ($('.shipping-price-content').length) {
                $('.shipping-price-content').remove();
            }
        }

    }).change();

    $('body').on('change', '.component-cart-list-item input[id^="product-"]', function() {
        var form = $(this).parents("form");

        var form_data = form.serializeArray();

        var input = $(this);

        var product_id = form.data('product_id');
        var quantity = $('input[id="product-'+product_id+'"]').val();

        $.ajax({
            method: "POST",
            url: form.attr('action'),
            data: form_data,
            success: function (data) {
                if (! (quantity > 0)) {
                    input.parents('.component-product-list-item').remove();
                }
                if(data.count) {
                    $('#subtotal-' + product_id).html(data.subtotal);
                    $('.component-header .cart-contents').html('(' + data.count + ' termék, ' + data.price.toLocaleString('hu-HU', {
                            maximumFractionDigits: 0,
                            style: 'currency',
                            currency: 'HUF'
                        }) + ')');
                    $('.cart-sum-price').html(data.price.toLocaleString('hu-HU', {
                        maximumFractionDigits: 0,
                        style: 'currency',
                        currency: 'HUF'
                    }));
                    $('.cart-sum-price-gross').html(data.price_gross.toLocaleString('hu-HU', {
                        maximumFractionDigits: 0,
                        style: 'currency',
                        currency: 'HUF'
                    }));
                    $('#item-price-'+product_id).html(data.item_price.toLocaleString('hu-HU', {
                        maximumFractionDigits: 0,
                        style: 'currency',
                        currency: 'HUF'
                    }));
                    $('select[name="shipping_type"]').change();
                }
                else if(typeof data.error != 'undefined'){
                    var alertModalElement = document.getElementById('alertModal');

                    if (alertModalElement) {
                        alertModalElement.querySelector('.alert-modal-title').innerHTML = 'Figyelem!'
                        alertModalElement.querySelector('.alert-modal-message').innerHTML = data.error;
                        $('input[id="product-'+product_id+'"]').val(data.max_amount);

                        const alertModal = window.bootstrap.Modal.getOrCreateInstance(alertModalElement);
                        alertModal.show();
                    }
                }
                else{
                    location.reload();
                }
            }
        });
    });
});
