$(document).ready(function(){

    $(window).scroll(function(){

        if($(window).scrollTop() <= 0){
            $('body').removeClass('scrolled');
        }
        else{
            $('body').addClass('scrolled');
        }

    });

});
