window.$ = window.jQuery = require('jquery');
window.bootstrap = require('bootstrap');

import IMask from 'imask';

require('./general');
require('./navbar');

require('./cart.js');



let loginModalElement = document.getElementById('loginModal');
if (loginModalElement.querySelector('.invalid-feedback')) {
    let loginModal = window.bootstrap.Modal.getOrCreateInstance(loginModalElement);
    loginModal.show();
}

const registrationModalElement = document.getElementById('registrationModal');
const tel_element = document.getElementById('tel');
var phoneMask = IMask(tel_element, {
    mask: '+{36}00/000-000[0]',
    lazy: false,  // make placeholder always visible
    placeholderChar: '_'     // defaults to '_'
});

const tax_element = document.getElementById('tax_number');
var taxMask = IMask(tax_element, {
    mask: '00000000-0-00',
    lazy: false,  // make placeholder always visible
    placeholderChar: '_'     // defaults to '_'
});


if (registrationModalElement.querySelector('.invalid-feedback')) {
    const registrationModal = window.bootstrap.Modal.getOrCreateInstance(registrationModalElement);
    registrationModal.hide();
}

const responseModalElement = document.getElementById('responseModal');
if (responseModalElement) {
    const responseModal = window.bootstrap.Modal.getOrCreateInstance(responseModalElement);
    responseModal.show();
}

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    var options = {
        'template': '<div class="tooltip" role="tooltip"><div class="tooltip-inner"></div></div>',
        'customClass': 'shadow p-1 bg-white rounded',
    };
    return new bootstrap.Tooltip(tooltipTriggerEl, options);
});

